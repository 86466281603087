<template>
    <div>
        <header>
            <van-nav-bar title="加入即得APP积分+鹏友值"/>
        </header>

        <body>
            <van-row>
                <p style="margin-bottom:0px; margin-left: 30px; font-size: 40px; color: rgb(16,77,238)">你好</p>
                <h1 style="margin-top:2px; margin-bottom:15px; margin-left: 30px; font-size: 40px; color: rgb(16,77,238);">鹏友</h1>
                <p style="margin-bottom:0px; margin-top:10px; margin-left: 30px; font-size: 20px; color: rgb(16,77,238)">诚邀你加入小鹏汽车鹏友会，</p>
                <p style="margin-top:0px; margin-left: 30px; font-size: 20px; color: rgb(16,77,238)">与鹏同行，探索更多精彩。</p>
            </van-row>
            <van-area title="请选择你当前区域" :area-list="areaList" style="margin-top:20px; margin-left: 10px; margin-right: 10px; border-radius: 10px" @confirm="confirmHandle" @cancel="cancelHandle"/>
        </body>

    </div>
</template>

<script>
export default {
    data() {
        return {
            areaList: {
                province_list: {
                    110000: '区域划分'
                },
                city_list: {
                    110100: '东区',
                    110200: '南区',
                    110300: '中西区',
                    110400: '北区',
                },
                county_list: {
                    // 东区：上海、江苏、浙江、安徽
                    110101: '上海',
                    110102: '江苏',
                    110103: '浙江',
                    110104: '安徽',
                    // 南区: 福建、江西、广东、广西、海南、香港、台湾、澳门
                    110201: '福建',
                    110202: '江西',
                    110203: '广东',
                    110204: '广西',
                    110205: '海南',
                    110206: '香港',
                    110207: '台湾',
                    110208: '澳门',
                    // 中西区：河南、湖北、湖南、重庆、四川、贵州、云南、西藏、陕西、甘肃、青海、宁夏、新疆
                    110301: '河南',
                    110302: '湖北',
                    110303: '湖南',
                    110304: '重庆',
                    110305: '四川',
                    110306: '贵州',
                    110307: '云南',
                    110308: '西藏',
                    110309: '陕西',
                    110310: '甘肃',
                    110311: '青海',
                    110312: '新疆',
                    // 北区：北京、天津、河北、山西、内蒙、辽宁、吉林、黑龙江、山东
                    110401: '北京',
                    110402: '天津',
                    110403: '河北',
                    110404: '山西',
                    110405: '内蒙',
                    110406: '辽宁',
                    110407: '吉林',
                    110408: '黑龙江',
                    110409: '山东',
                }
            }
        }
    },
    methods: {
        confirmHandle() {
            this.$router.push('./selectResult')
        },
        cancelHandle() {
            this.$router.push('./')
        }
    }
}
</script>

<style scoped>
</style>